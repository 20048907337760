import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  .introText {
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 300;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #585858;
    h3 {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: left;
      color: #172e56;
      display: inline;
    }
  }
  h2 {
    font-family: Oswald;
    font-size: 48px;
    font-weight: 200;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 61px;
    margin-bottom: 61px;
  }
  .strategies {
    max-width: 1128px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 25px;
    .item {
      border: 1px solid #41b6e6;
      padding: 18px 26px;
      min-height: 264px;
      max-width: 262px;
      box-sizing: border-box;
      .title {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        color: #007dba;
        margin-bottom: 7px;
      }
      .body {
        font-family: Roboto;
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #585858;
      }
    }
    @media (min-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 900px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (min-width: 1160px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
`;

function strategies(props) {
  return (
    <Wrapper>
      <div className="introText">
        {props.text && <div dangerouslySetInnerHTML={{ __html: props.text }}></div>}
        {props.title && <h2>{props.title}</h2>}
      </div>
      <div className="inner strategies col-4">
        {props.data.map((row, index) => (
          <div className="item" key={index}>
            <div className="title">{row.title}</div>
            <div className="body" dangerouslySetInnerHTML={{ __html: row.text }}></div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
export default strategies;
