import React from 'react';
import styled from 'styled-components';

import arrowSVG from '../../../img/services/relatedservices/arrow-right-white.svg';

import Button from './button';
import { Link } from 'gatsby';

const Wrapper = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 94px;
  margin-bottom: 70px;
  .introText {
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
    font-family: Roboto;
    font-size: 24px;
    font-weight: 300;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #585858;
    h2 {
      font-family: Oswald;
      font-size: 32px;
      font-weight: 300;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 37px;
    }
    p {
      font-size: 18px;
      line-height: 26px;
      max-width: 900px;
      margin: 0 auto 37px;
      text-align: center;
    }
  }
  .wrap-link {
    text-align: center;
    margin-top: 15px;
    position: relative;
    .wrap-btn {
      margin: 0 auto;
      text-align: center;
      max-width: 1133px;
    }
  }
  .related-services {
    max-width: 1128px;
    width: 100%;
    margin: 0 auto 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 39px;
    @media (min-width: 1208px) {
      justify-content: flex-start;
    }
    .item {
      max-width: 350px;
      width: 100%;
      background-color: #f2f2f2;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #067eba;
      height: 100px;
      padding-left: 24px;
      padding-right: 67px;
      text-align: center;
      text-decoration: none;
      font-family: Roboto;
      font-size: 21px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      color: #067eba;
      position: relative;
      transition: transform 180ms linear;
      background-color: #fff;
      @media (max-width: 500px) {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 18px;
        line-height: 25px;
      }
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 43px;
        height: 100%;
        background-color: #067eba;
        background-image: url(${arrowSVG});
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover,
      &:focus,
      &:active {
        transform: translate(6px, -8px);
      }
    }
  }
`;

function relatedServices(props) {
  return (
    <Wrapper>
      <div className="introText">
        {props.title && <h2>{props.title}</h2>}
        {props.text && <p>{props.text}</p>}
      </div>
      <div className="inner related-services">
        {props.data.map((row, index) => (
          <div className="item" key={index}>
            <Link to={row.link}>{row.text}</Link>
          </div>
        ))}
      </div>
      <div className="wrap-link">
        <div className="wrap-btn">
          <Button text={props.buttonText} path={props.buttonLink} type="black" />
        </div>
      </div>
    </Wrapper>
  );
}
export default relatedServices;
