import React from 'react';
import styled from 'styled-components';
import Bg from './../../../img/services/bg-stats.jpg';
import CountUp from 'react-countup';
import useVisibility from '../../../utils/useVisibility';

const Wrap = styled.div`
  background-image: url('${Bg}');
  background-size: cover;

  width: calc(100% - 80px);
  padding-right: 40px;
  padding-left: 40px;
  margin-top: -20px;
  padding-bottom: 30px;
  padding-top: 80px;

  .inner {
    max-width: 1133px;
    width: 100%;
    margin: 0 auto;

    .wrap-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
    }
  }

  h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    line-height: 52px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 0;
  }

  .part {
    background-size: 100% 100%;
    width: 256px;
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;

    .number {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 72px;
      line-height: 72px;
      align-items: center;
      text-align: center;
      color: #bce0f4;
      margin-bottom: 30px;
    }
    .label {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.02em;
      margin-bottom: 30px;
    }
    .source {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #41b6e6;
    }
  }

  @media (max-width: 1100px) {
    .inner .wrap-content {
      max-width: 600px;
      margin: 0 auto;
    }
  }
  @media (max-width: 600px) {
    .inner .wrap-content {
      max-width: 400px;
      margin: 0 auto;
      justify-content: center;
    }
  }
`;

function Insights(props) {
  let isVisibleRef = [useVisibility(-100, 1), useVisibility(-100, 2), useVisibility(-100, 3), useVisibility(-100, 4)];

  return (
    <>
      <Wrap>
        <div className="inner">
          <h2>{props.title}</h2>
        </div>
        <div className="inner">
          <div className="wrap-content">
            {props.insights.map((row, index) => (
              <div className="part" key={index}>
                {/* <div className="number">{row.number}</div> */}
                {/*TODO: figure out prefix */}
                <div className="number" ref={isVisibleRef[index][1]}>
                  {isVisibleRef[index][0] ? (
                    <CountUp
                      start={0}
                      delay={0}
                      end={parseFloat(row.number)}
                      duration={2}
                      separator=""
                      decimals={row.number.toString().split('.')[1] ? row.number.toString().split('.')[1].length : 0}
                      decimal="."
                      prefix={row.prefix ? row.prefix : ''}
                      suffix={row.suffix ? row.suffix : ''}
                    />
                  ) : (
                    <span>0</span>
                  )}
                </div>
                <div className="label">{row.label}</div>
                <div className="source">- {row.source}</div>
              </div>
            ))}
          </div>
        </div>
      </Wrap>
    </>
  );
}
export default Insights;
